import React from 'react';
import Layout from '../components/layout';

const Contact = () => {
  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mx-8">
          <h1>{process.env.GATSBY_LANGUAGE === "no" ? "Kontakt oss" : "Kontakta oss på Branschlösningar.se"}</h1>
          <p>
            {process.env.GATSBY_LANGUAGE === "no" ? `Bransjeløsninger.no er utgitt av PDC Sweden. Ta gjerne kontakt med oss dersom du har spørsmål om nettsiden, vil legge opp en løsning eller endre på en allerede publisert løsning. Finner du ikke en løsning som passer deg, så hjelper vi deg gjerne i samarbeid med Microsoft.` : `Branschlösningar.se är utgiven av Partner Development Sweden. Hör
            gärna av dig om du undrar något över siten, vill lägga upp en
            lösning eller göra en ändring i en redan publicerad lösning. Hittar
            du inte en lösning som passar dig, kontakta oss så hjälper vi dig
            att hitta rätt lösning.`}
          </p>
          <p className='mt-6'>
            Partner Development Center Sweden AB
            <br />
            c/o Interventor
            <br />
            Tulegatan 20
            <br />
            113 53 Stockholm
            <br />
            Telefon: 0709-550 450 <br />
            E-post: <a href="mailto:info@pdcsweden.se">info@pdcsweden.se</a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
